// App.tsx
import React, { useEffect, useState } from "react";
import { VehicleDetails } from "../components/VehicleDetails";
import { ModelSelection } from "../components/ModelSelection";
// import { Instructions } from "../components/Instructions";
import { Helmet } from "react-helmet";
// import DegChart3Y from "../components/graphs/DegChart3Y";
// import DegChartSX from "../components/graphs/DegChartSX";

function Home() {
  const [wattHours, setWatthours] = useState(0);
  const [mileage, setMileage] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [capacity, setCapacity] = useState(0);
  const [modelCapacity, setModelCapacity] = useState<null | number>(null);
  const [capacityLostPercentage, setCapacityLostPercentage] = useState<
    null | number
  >(null);
  //const [model, setModel] = useState<null | string>(null);

  useEffect(() => {
    //(window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  useEffect(() => {
    const totalWattsUsed = wattHours * mileage;
    const totalKwhs = totalWattsUsed / 1000;
    const newCapacity = (totalKwhs / percentage) * 100;

    if (!isNaN(newCapacity) && percentage) {
      setCapacity(newCapacity);
    }
  }, [wattHours, mileage, percentage]);

  useEffect(() => {
    if (capacity && modelCapacity) {
      setCapacityLostPercentage((capacity / modelCapacity) * 100);
    } else {
      setCapacityLostPercentage(null);
    }
  }, [modelCapacity, capacity]);

  return (
    <>
      <Helmet>
        <title>Tesla Battery Health Check | Tesla Battery Check</title>
        <meta
          name="description"
          content="Check the health and warranty status of your Tesla battery with our easy-to-use tool. Get accurate information to ensure your Tesla's battery is in optimal condition."
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="Tesla, battery health, Tesla battery check, Model S, Model X, Model 3, Model Y, Tesla warranty, battery diagnostics, EV battery health"
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://teslabatterycheck.com/" />
        <meta
          property="og:title"
          content="Tesla Battery Health Check | Tesla Battery Check"
        />
        <meta
          property="og:description"
          content="Check the health and warranty status of your Tesla battery with our easy-to-use tool. Get accurate information to ensure your Tesla's battery is in optimal condition."
        />
        {/* Twitter */}
        <meta property="twitter:url" content="https://teslabatterycheck.com/" />
        <meta
          property="twitter:title"
          content="Tesla Battery Health Check | Tesla Battery Check"
        />
        <meta
          property="twitter:description"
          content="Check the health and warranty status of your Tesla battery with our easy-to-use tool. Get accurate information to ensure your Tesla's battery is in optimal condition."
        />
      </Helmet>
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="visually-hidden">
          Tesla battery Capacity and health calculator
        </h1>
        <div className="w-full">
          <p className="w-full mb-8 text-3xl">
            Check the battery health of any Tesla
          </p>
          <p className="mb-8">
            It can be hard to tell the "health" or remaining capacity of a Tesla
            battery without running diagnostics that take days to complete, or
            having access to specialised apps that track a Teslas battery
            health.
          </p>
          <p>
            This simple calculator aims to give you an instant snapshot of any
            Tesla with information available within the Tesla infotainment
            system. Just enter the details below and see an instant estimate of
            the battery capacity.
          </p>
        </div>
        {/*<Instructions />
        <div>
          <ins className="adsbygoogle"
          style={{display: "block"}}
          data-ad-client="ca-pub-9239817739162453"
          data-ad-slot="6517542624"
          data-ad-format="auto"
          data-full-width-responsive="true"></ins>
        </div>*/}
        <div className="grid grid-cols-1 md:grid-cols-2 mt-8 gap-6">
          <VehicleDetails
            setWatthours={setWatthours}
            setMileage={setMileage}
            setPercentage={setPercentage}
            capacity={capacity}
            capacityLostPercentage={capacityLostPercentage}
          />
          <ModelSelection
            setModelCapacity={setModelCapacity}
            setModel={() => null}
          />
        </div>
        {/*
        {capacityLostPercentage &&
          model &&
          (model.includes("3") || model.includes("Y")) && (
            <DegChart3Y
              currentRetention={Math.round(Number(capacityLostPercentage))}
            />
          )}

        {capacityLostPercentage &&
          model &&
          (model.includes("S") || model.includes("X")) && (
            <DegChartSX
              currentRetention={Math.round(Number(capacityLostPercentage))}
            />
          )}*/}
      </div>
    </>
  );
}

export default Home;
