// components/ModelSelection.tsx
import React from "react";
import ModelSelector from "./ModelSelector";

interface ModelSelectionProps {
  setModelCapacity: (capacity: number | null) => void;
  setModel: (model: string | null) => void;
}

export const ModelSelection: React.FC<ModelSelectionProps> = ({
  setModelCapacity,
  setModel,
}) => (
  <div className="bg-white rounded-lg shadow-lg border-cyan-100 border overflow-hidden">
    <div className="p-4">
      <ModelSelector setModelCapacity={setModelCapacity} setModel={setModel} />
    </div>
  </div>
);
