import styled from "styled-components";
import bg from "./assets/bg.png";

export const Main = styled.main`
  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${bg});
    background-repeat: repeat;
    opacity: 0;
    z-index: -1;
    pointer-events: none;
  }
`;
