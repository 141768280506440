import React, { useMemo, useState } from "react";
import { teslaBatteryData } from "../data/data";
import { Calendar, Gauge } from "lucide-react";
import Citation from "../components/Citation";
import { sortedVariants } from "../helpers";
import { Helmet } from "react-helmet";

const getWarrantyMiles = (model: string, variant: string) => {
  if (model === "Model S" || model === "Model X") return 150000;

  if (
    (model === "Model 3" || model === "Model Y") &&
    (variant === "Model 3" ||
      variant === "Model Y" ||
      variant === "Standard Range Plus" ||
      variant === "Standard Range Plus LFP")
  ) {
    return 100000;
  }

  return 120000;
};

const BatteryInfo: React.FC = () => {
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedVariant, setSelectedVariant] = useState("");
  const [purchaseMonth, setPurchaseMonth] = useState("1");
  const [mileage, setMileage] = useState("");

  const models = Object.keys(teslaBatteryData);
  const years = selectedModel
    ? Array.from(
        new Set(
          teslaBatteryData[selectedModel].flatMap((item) => item.yearList)
        )
      )
    : [];

  const variants = useMemo(() => {
    if (!selectedModel || !selectedYear) return [];

    // Create a Map using variant name as key to ensure uniqueness
    const variantMap = new Map();

    teslaBatteryData[selectedModel]
      .filter((variant) => variant.yearList.includes(Number(selectedYear)))
      .forEach((variant) => {
        // Only add if we haven't seen this variant name before
        if (!variantMap.has(variant.variant)) {
          variantMap.set(variant.variant, variant);
        }
      });

    // Convert Map values back to array and sort
    return sortedVariants(Array.from(variantMap.values()))
      .reverse()
      .map((item) => item.variant);
  }, [selectedModel, selectedYear]);

  // Rest of the component remains the same...

  const getWarrantyInfo = () => {
    if (!selectedModel || !selectedYear || !selectedVariant) return null;
    const batteryInfo = teslaBatteryData[selectedModel].find(
      (item) =>
        item.variant === selectedVariant &&
        item.yearList.includes(Number(selectedYear))
    );
    if (!batteryInfo) return null;

    const currentDate = new Date();
    const purchaseDate = new Date(
      Number(selectedYear),
      Number(purchaseMonth) - 1
    );
    const monthsSincePurchase =
      (currentDate.getFullYear() - purchaseDate.getFullYear()) * 12 +
      currentDate.getMonth() -
      purchaseDate.getMonth();
    const warrantyMonths = 96;
    const remainingMonths = warrantyMonths - monthsSincePurchase;
    const remainingYears = Math.floor(remainingMonths / 12);
    const remainingMonthsDisplay = remainingMonths % 12;

    const warrantyMiles = getWarrantyMiles(selectedModel, selectedVariant);
    const remainingMiles = warrantyMiles - Number(mileage);

    const isWarrantyExpired = remainingMonths <= 0 || remainingMiles <= 0;

    return {
      vehicle: `${selectedYear} ${selectedModel} ${selectedVariant}`,
      warrantyMiles,
      remainingYears,
      remainingMonthsDisplay,
      remainingMiles,
      purchaseDate,
      isValid: !isWarrantyExpired,
      isWarrantyExpired,
    };
  };

  const warrantyInfo = getWarrantyInfo();

  return (
    <>
      <Helmet>
        <title>Tesla Battery Warranty Check | Tesla Battery Check</title>
        <meta
          name="description"
          content="Check your Tesla battery's warranty status easily and accurately. Enter your details to see if your battery is covered under warranty."
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="Tesla, battery warranty check, Tesla warranty, Tesla battery health, Tesla battery coverage"
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://teslabatterycheck.com/warranty-check"
        />
        <meta
          property="og:title"
          content="Tesla Battery Warranty Check | Tesla Battery Check"
        />
        <meta
          property="og:description"
          content="Check your Tesla battery's warranty status easily and accurately. Enter your details to see if your battery is covered under warranty."
        />
        {/* Twitter */}
        <meta
          property="twitter:url"
          content="https://teslabatterycheck.com/warranty-check"
        />
        <meta
          property="twitter:title"
          content="Tesla Battery Warranty Check | Tesla Battery Check"
        />
        <meta
          property="twitter:description"
          content="Check your Tesla battery's warranty status easily and accurately. Enter your details to see if your battery is covered under warranty."
        />
      </Helmet>
      <article className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="visually-hidden">
          Battery & Drive Unit Warranty Checker
        </h1>
        <div className="w-full">
          <p className="mb-8 text-3xl">
            Check remaining Battery and Drive Unit warranty
          </p>
          <p className="mb-8">
            Use the calculator below to see the remaining warranty time left on
            any Tesla. This warranty calculator is specifically for Tesla's
            "Battery and Drive Unit" warranty only, this is seperate to the
            "Basic Vehicle Limited Warranty".
          </p>
          <p className="mb-8">
            If you would like to check a Tesla's "Basic Vehicle Limited
            Warranty" length please visit the{" "}
            <a
              href="https://www.tesla.com/support/vehicle-warranty"
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              Tesla website for you country
            </a>
          </p>
        </div>
        <div className="space-y-6">
          {/* Vehicle Selection Card */}
          <section className="bg-white p-6 rounded-lg shadow-lg border-cyan-100 border overflow-hidden">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              Your vehicle model
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Model
                </label>
                <select
                  className="w-full px-4 py-2 bg-gray-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:bg-white transition-colors"
                  value={selectedModel}
                  onChange={(e) => {
                    setSelectedModel(e.target.value);
                    setSelectedYear("");
                    setSelectedVariant("");
                  }}
                >
                  <option value="">Select Model</option>
                  {models.map((model) => (
                    <option key={`model-${model}`} value={model}>
                      {model}
                    </option>
                  ))}
                </select>
              </div>

              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Year
                </label>
                <select
                  className="w-full px-4 py-2 bg-gray-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:bg-white transition-colors"
                  value={selectedYear}
                  onChange={(e) => {
                    setSelectedYear(e.target.value);
                    setSelectedVariant("");
                  }}
                  disabled={!selectedModel}
                >
                  <option value="">Select Year</option>
                  {years.map((year) => (
                    <option
                      key={`year-${year}-${selectedModel}`}
                      value={String(year)}
                    >
                      {year}
                    </option>
                  ))}
                </select>
              </div>

              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Variant
                </label>
                <select
                  className="w-full px-4 py-2 bg-gray-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:bg-white transition-colors"
                  value={selectedVariant}
                  onChange={(e) => setSelectedVariant(e.target.value)}
                  disabled={!selectedModel || !selectedYear}
                >
                  <option value="">Select Variant</option>
                  {variants.map((variant) => (
                    <option
                      key={`variant-${variant}-${selectedYear}`}
                      value={variant}
                    >
                      {variant}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <h2 className="text-xl font-semibold text-gray-800 mt-8 mb-4">
              Your purchase details
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Purchase Month
                </label>
                <select
                  className="w-full px-4 py-2 bg-gray-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:bg-white transition-colors"
                  value={purchaseMonth}
                  onChange={(e) => setPurchaseMonth(e.target.value)}
                >
                  <option value="">Select Month</option>
                  {Array.from({ length: 12 }, (_, i) =>
                    String(i + 1).padStart(2, "0")
                  ).map((month, index) => (
                    <option
                      key={`purchase-month-${month}-${selectedYear}`}
                      value={month}
                    >
                      {new Date(0, Number(month) - 1).toLocaleString(
                        "default",
                        {
                          month: "long",
                        }
                      )}
                    </option>
                  ))}
                </select>
              </div>

              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Current Mileage
                </label>
                <input
                  type="number"
                  placeholder="Enter current mileage"
                  className="w-full px-4 py-2 bg-gray-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:bg-white transition-colors"
                  value={mileage}
                  onChange={(e) => setMileage(e.target.value)}
                />
              </div>
            </div>
          </section>

          {/* Warranty Status Card */}
          {warrantyInfo && (
            <section className="bg-white p-6 rounded-lg shadow-lg border border-gray-100">
              <h2 className="text-xl font-semibold text-gray-800 mb-4">
                Warranty Status <Citation id="1" />
              </h2>

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                {/* Vehicle Info */}
                <div className="bg-gray-50 p-4 rounded-lg">
                  <h3 className="font-medium text-gray-700 mb-2">
                    Vehicle Information
                  </h3>
                  <p className="text-lg font-semibold text-gray-900">
                    {warrantyInfo.vehicle}
                  </p>
                  <p className="text-sm text-gray-600 mt-1">
                    Purchase Date:{" "}
                    {warrantyInfo.purchaseDate.toLocaleString("default", {
                      month: "long",
                      year: "numeric",
                    })}
                  </p>
                </div>

                {/* Warranty Coverage */}
                <div className="bg-blue-50 p-4 rounded-lg">
                  <div className="flex items-start justify-between">
                    <h3 className="font-medium text-blue-900">
                      Warranty Coverage
                    </h3>
                  </div>
                  <p className="text-blue-900 mt-2">
                    8-year or {warrantyInfo.warrantyMiles.toLocaleString()} mile
                    battery warranty
                  </p>
                  <p className="text-sm text-blue-800 mt-1">
                    Minimum 70% retention of Battery capacity
                  </p>
                </div>

                {/* Time Remaining */}
                <div
                  className={`p-4 rounded-lg flex items-start gap-3 ${
                    !warrantyInfo.isWarrantyExpired
                      ? "bg-green-50"
                      : "bg-red-50"
                  }`}
                >
                  <Calendar
                    className={`h-5 w-5 ${
                      !warrantyInfo.isWarrantyExpired
                        ? "text-green-600"
                        : "text-red-600"
                    }`}
                  />
                  <div>
                    <h3
                      className={`font-medium ${
                        !warrantyInfo.isWarrantyExpired
                          ? "text-green-900"
                          : "text-red-900"
                      }`}
                    >
                      Time Remaining
                    </h3>
                    <p
                      className={`text-lg font-semibold ${
                        !warrantyInfo.isWarrantyExpired
                          ? "text-green-900"
                          : "text-red-900"
                      }`}
                    >
                      {!warrantyInfo.isWarrantyExpired
                        ? `${warrantyInfo.remainingYears} years and ${warrantyInfo.remainingMonthsDisplay} months`
                        : "Warranty Expired"}
                    </p>
                  </div>
                </div>

                {/* Mileage Remaining */}
                <div
                  className={`p-4 rounded-lg flex items-start gap-3 ${
                    !warrantyInfo.isWarrantyExpired
                      ? "bg-green-50"
                      : "bg-red-50"
                  }`}
                >
                  <Gauge
                    className={`h-5 w-5 ${
                      !warrantyInfo.isWarrantyExpired
                        ? "text-green-600"
                        : "text-red-600"
                    }`}
                  />
                  <div>
                    <h3
                      className={`font-medium ${
                        !warrantyInfo.isWarrantyExpired
                          ? "text-green-900"
                          : "text-red-900"
                      }`}
                    >
                      Mileage Remaining
                    </h3>
                    <p
                      className={`text-lg font-semibold ${
                        !warrantyInfo.isWarrantyExpired
                          ? "text-green-900"
                          : "text-red-900"
                      }`}
                    >
                      {!warrantyInfo.isWarrantyExpired
                        ? `${warrantyInfo.remainingMiles.toLocaleString()} miles`
                        : "Warranty Expired"}
                    </p>
                  </div>
                </div>
              </div>
            </section>
          )}

          <table className="min-w-full text-sm mt-4 bg-white border border-gray-300 rounded-lg">
            <thead className="bg-gray-200 text-gray-700">
              <tr>
                <th className="w-1/3 py-2 px-4 text-left border-b">Model</th>
                <th className="w-2/3 py-2 px-4 text-left border-b">
                  Warranty Coverage
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              <tr className="hover:bg-gray-50">
                <td className="w-1/3 px-6 py-4 text-sm">
                  <p className="font-medium">Model S</p>
                  <p className="font-medium">Model X</p>
                </td>
                <td className="w-2/3 px-6 py-4 text-sm">
                  Battery warranty covers 8 years or 150,000 miles (whichever
                  occurs first) with guaranteed 70% battery capacity retention
                </td>
              </tr>
              <tr className="hover:bg-gray-50">
                <td className="w-1/3 px-6 py-4 text-sm">
                  <p className="font-medium">Model 3 RWD</p>
                  <p className="font-medium">Model Y RWD</p>
                </td>
                <td className="w-2/3 px-6 py-4 text-sm">
                  Battery warranty covers 8 years or 100,000 miles (whichever
                  occurs first) with guaranteed 70% battery capacity retention
                </td>
              </tr>
              <tr className="hover:bg-gray-50">
                <td className="w-1/3 px-6 py-4 text-sm">
                  <p className="font-medium">Model 3 Long Range</p>
                  <p className="font-medium">Model 3 Performance</p>
                  <p className="font-medium">Model Y Long Range</p>
                  <p className="font-medium">Model Y Performance</p>
                </td>
                <td className="w-2/3 px-6 py-4 text-sm">
                  Battery warranty covers 8 years or 120,000 miles (whichever
                  occurs first) with guaranteed 70% battery capacity retention
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <footer className="text-sm text-gray-500 mt-8">
          <h2 className="font-semibold mb-2">References</h2>
          <ol className="list-decimal ml-6 space-y-1">
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.tesla.com/en_gb/support/vehicle-warranty"
              >
                Tesla - Vehicle Warranty
              </a>
            </li>
          </ol>
        </footer>
      </article>
    </>
  );
};

export default BatteryInfo;
