import React, { useState, useMemo, ChangeEvent } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";
import energyScreen from "../assets/energy-screen.jpg";
import energyScreenNew from "../assets/energy-screen-new.jpg";

type ConsumptionUnit = "whkm" | "whmi";

interface Currency {
  code: string;
  symbol: string;
  subUnit: string;
  divider: number;
}

const EnergyCalculator: React.FC = () => {
  const [consumptionUnit, setConsumptionUnit] =
    useState<ConsumptionUnit>("whmi");
  const [consumption, setConsumption] = useState<string>("");
  const [pricePerKwh, setPricePerKwh] = useState<string>("");
  const [currency, setCurrency] = useState<string>("GBP");
  const [distance, setDistance] = useState<string>("");
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [isChargingCostsOpen, setIsChargingCostsOpen] = useState(false);

  const currencies: Currency[] = useMemo(
    () => [
      { code: "GBP", symbol: "£", subUnit: "pence", divider: 100 },
      { code: "EUR", symbol: "€", subUnit: "cents", divider: 100 },
      { code: "USD", symbol: "$", subUnit: "cents", divider: 100 },
      { code: "JPY", symbol: "¥", subUnit: "sen", divider: 100 },
      { code: "AUD", symbol: "A$", subUnit: "cents", divider: 100 },
      { code: "CAD", symbol: "C$", subUnit: "cents", divider: 100 },
    ],
    []
  );

  const currentCurrency = useMemo(
    () => currencies.find((c) => c.code === currency) || currencies[0],
    [currency, currencies]
  );

  const handlePriceChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    if (value === "") {
      setPricePerKwh(value);
      return;
    }
    const regex = /^\d*$/;
    if (regex.test(value)) {
      setPricePerKwh(value);
    }
  };

  const pricePerDistance: number | null = useMemo(() => {
    if (!consumption || !pricePerKwh) return null;
    const priceInMainUnit = parseFloat(pricePerKwh) / currentCurrency.divider;
    const consumptionKwh = parseFloat(consumption) / 1000;
    return consumptionKwh * priceInMainUnit;
  }, [consumption, pricePerKwh, currentCurrency.divider]);

  const journeyCost: number | null = useMemo(() => {
    if (!pricePerDistance || !distance) return null;
    return pricePerDistance * parseFloat(distance);
  }, [pricePerDistance, distance]);

  const formatCurrency = (value: number): string => {
    if (!value) return "";
    return new Intl.NumberFormat(undefined, {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  return (
    <article className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="visually-hidden">Tesla Journey Cost Calculator</h1>

      <div className="w-full mb-8">
        <p className="mb-6 text-3xl">
          Tesla "Cost Per Mile" and Journey Cost Calculator
        </p>
        <p>
          Calculate your cost per mile or kilometer or total journey cost based
          on your energy consumption and get a clear idea of how much you could
          save versus your current vehicle.
        </p>
      </div>
      <div className="mx-auto bg-white rounded-lg shadow-lg border-cyan-100 border overflow-hidden">
        <div className="p-6">
          {/* Help Section */}
          <div className="mb-6 space-y-2">
            <button
              onClick={() => setIsHelpOpen(!isHelpOpen)}
              className="flex items-center gap-2 hover:text-blue-700"
            >
              How to find your energy consumption
              {isHelpOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
            </button>

            {isHelpOpen && (
              <div className="rounded-lg border p-4 bg-gray-50">
                <div className="mb-2">
                  <p className="text-sm mb-2">
                    To find your energy consumption (Wh/mile or Wh/km):
                  </p>
                  <ol className="text-sm list-decimal ml-4">
                    <li>Open your Tesla's Energy app</li>
                    <li>Select 30 mi or 50 km from the range options</li>
                    <li>
                      Look for the "Average" consumption value (middle left)
                    </li>
                    <li>Enter this number in the calculator below</li>
                  </ol>
                </div>
                <div className="mt-4">
                  <img
                    src={energyScreenNew}
                    alt="New Tesla Energy Screen Example"
                    className="rounded-md w-full mb-3"
                  />
                  <img
                    src={energyScreen}
                    alt="Tesla Energy Screen Example"
                    className="rounded-md w-full"
                  />
                </div>
              </div>
            )}
          </div>

          {/* Calculator Content */}
          <div className="mb-6">
            <label className="block text-sm font-medium mb-2">
              Energy Consumption
            </label>
            <div className="flex gap-2">
              <input
                type="number"
                value={consumption}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setConsumption(e.target.value)
                }
                placeholder="Enter consumption"
                className="flex-1 rounded border px-3 py-2"
              />
              <select
                value={consumptionUnit}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  setConsumptionUnit(e.target.value as ConsumptionUnit)
                }
                className="w-32 rounded border px-3 py-2 bg-white"
              >
                <option value="whmi">Wh/mile</option>
                <option value="whkm">Wh/km</option>
              </select>
            </div>
          </div>

          <div className="mb-1">
            <label className="block text-sm font-medium mb-2">
              Price per kWh (in {currentCurrency.subUnit})
            </label>
            <div className="flex gap-2">
              <input
                type="text"
                inputMode="numeric"
                value={pricePerKwh}
                onChange={handlePriceChange}
                placeholder={`Enter price in ${currentCurrency.subUnit}`}
                className="flex-1 rounded border px-3 py-2 text-xl"
              />
              <select
                value={currency}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  setCurrency(e.target.value);
                  setPricePerKwh("");
                }}
                className="w-32 rounded border px-3 py-2 bg-white"
              >
                {currencies.map((curr) => (
                  <option key={curr.code} value={curr.code}>
                    {curr.code} ({curr.symbol})
                  </option>
                ))}
              </select>
            </div>
            <div className="text-sm text-gray-500 mt-1">
              Example: Enter 15 for {currentCurrency.subUnit} instead of{" "}
              {currentCurrency.symbol}0.15
            </div>
          </div>

          {/* UK Charging Costs Section */}
          <div className="mb-6 space-y-2">
            <button
              onClick={() => setIsChargingCostsOpen(!isChargingCostsOpen)}
              className="flex items-center gap-2 text-sm text-blue-600 hover:text-blue-700"
            >
              Typical Charging Costs (UK)
              {isChargingCostsOpen ? (
                <ChevronUp size={16} />
              ) : (
                <ChevronDown size={16} />
              )}
            </button>

            {isChargingCostsOpen && (
              <div className="rounded-lg border p-4 bg-gray-50">
                <ul className="list-disc ml-4 space-y-1 text-sm">
                  <li>Home Charging: Based on your energy supplier's rate</li>
                  <li>Tesla Superchargers: 30-45 pence per kWh</li>
                  <li>
                    Public Chargers: 50-90 pence per kWh (varies by provider and
                    charging speed)
                  </li>
                </ul>
              </div>
            )}
          </div>

          {pricePerDistance !== null && (
            <div className="mb-6 p-4 bg-gray-50 rounded-lg">
              <div className="text-sm text-gray-600">
                Price per {consumptionUnit === "whkm" ? "kilometer" : "mile"}
              </div>
              <div className="text-2xl font-bold">
                {formatCurrency(pricePerDistance)}
              </div>
            </div>
          )}

          {pricePerDistance !== null && (
            <div className="mb-6">
              <label className="block text-sm font-medium mb-2">
                Journey Distance ({consumptionUnit === "whkm" ? "km" : "miles"})
              </label>
              <input
                type="number"
                value={distance}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setDistance(e.target.value)
                }
                placeholder={`Enter distance in ${
                  consumptionUnit === "whkm" ? "kilometers" : "miles"
                }`}
                className="w-full rounded border px-3 py-2"
              />
            </div>
          )}

          {journeyCost !== null && (
            <div className="p-4 bg-blue-50 rounded-lg">
              <div className="text-sm text-blue-600">Total Journey Cost</div>
              <div className="text-2xl font-bold">
                {formatCurrency(journeyCost)}
              </div>
            </div>
          )}
        </div>
      </div>
    </article>
  );
};

export default EnergyCalculator;
